//
// joyride.scss
//

.react-joyride__overlay {
    z-index: 1002 !important;
}

.__floater__open{
    z-index: 1003 !important;
}