//
// landing.scss
//

.landing {
    /*******************
    1.General
    *******************/

    font-size: 15px;
    background-color: $gray-100;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.5;
    }

    a {
        text-decoration: none !important;
        outline: none;
    }

    p {
        line-height: 1.6;
        font-size: 15px;
    }

    .row > * {
        position: relative;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

    /*******************
    2.Helper
    *******************/

    .card {
        border: none;
        box-shadow: 0 10px 35px 0 rgba($black, 0.1);
    }

    .bg-gradient {
        background: linear-gradient(360deg, #fff 0%, #3bafda 78%);
    }

    .btn {
        font-weight: 500;
    }

    .dropdown-menu-end {
        right: 0 !important;
        left: auto !important;
    }

    .avatar-sm {
        width: 32px;
        height: 32px;
    }

    .avatar-md {
        width: 56px;
        height: 56px;
    }

    .section {
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .font-size-13 {
        font-size: 13px;
    }

    .font-size-14 {
        font-size: 14px;
    }

    /******************
    3.Navbar
    *******************/

    .tagline {
        position: absolute;
        width: 100%;
        z-index: 9;
        .info-link {
            color: rgba($gray-100, 0.7);
            li {
                line-height: 40px;
                padding: 0px 7px;
                a {
                    color: rgba($gray-100, 0.7);
                }
            }
        }
        .social-links {
            li {
                a {
                    line-height: 30px;
                    padding: 0px 7px;
                    color: rgba($white, 0.7);
                    background-color: #3e4754;
                    display: block;
                    height: 30px;
                    width: 30px;
                    margin: 5px 0;
                    border-radius: 50%;
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    .logo {
        .logo-dark {
            display: $logo-auth-dark-display;
        }
        .logo-light {
            display: $logo-auth-light-display;
        }
    }

    .navbar-custom {
        padding: 0;
        width: 100%;
        z-index: 999;
        transition: all 0.4s;
        background: $light;
        top: 40px;
        left: 0;
        // .logo {
        //     .logo-light {
        //         display: inline-block;
        //     }
        //     .logo-dark {
        //         display: none;
        //     }
        // }
        .navbar-nav {
            .nav-item {
                position: relative;
                .dropdown {
                    .dropdown-menu {
                        .dropdown-item {
                            color: $gray-700;
                        }
                    }
                }
                .nav-link {
                    line-height: 26px;
                    transition: all 0.4s;
                    color: $gray-700;
                    background-color: transparent !important;
                    padding: 22px 10px;
                    margin: 0 7px;
                    font-weight: 500;
                    &:hover,
                    &.active,
                    &:active {
                        color: $dark;
                    }
                }
                &:last-child {
                    .nav-link {
                        padding-right: 0px;
                    }
                }
            }
        }
    }

    .navbar-toggler {
        font-size: 24px;
        margin-top: 5px;
        margin-bottom: 0px;
        color: $white;
    }

    @media (min-width: 992px) {
        .navbar-custom {
            .navbar-nav {
                .nav-item {
                    .dropdown {
                        &:hover {
                            .dropdown-menu {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    /*--- navbar sticky ---*/

    .nav-sticky {
        .navbar-custom {
            padding: 0px;
            background-color: $light;
            box-shadow: 0 10px 35px 0 rgba(154, 161, 171, 0.1);
            top: 0px;
            .logo {
                .logo-dark {
                    display: $logo-auth-dark-display;
                }
                .logo-light {
                    display: $logo-auth-light-display;
                }
            }

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: rgba($dark, 0.8);
                        &:hover,
                        &.active,
                        &:active {
                            color: $purple;
                        }
                    }
                }
            }
        }
    }

    /*******************
    4.Home
*******************/

    .bg-home {
        position: relative;
        padding-top: 190px;

        .home-img {
            position: relative;
            top: 70px;
            z-index: 1;
        }
    }

    .bg-overlay {
        //background: url(../../../images/landing/overlay.png);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .home-desc {
        color: #d4edf5;
    }

    /*******************
    5.Services
*******************/

    .services-box {
        .services-icon {
            display: inline-block;
            position: relative;
            i {
                width: 64px;
                height: 64px;
                line-height: 64px;
                background: rgba($primary, 0.6);
                color: $white;
                border-radius: 92% 50% 80% 50%;
                z-index: 99;
                position: relative;
            }
            &:before {
                content: "";
                position: absolute;
                width: 80px;
                height: 64px;
                background: rgba($purple, 0.3);
                right: -8px;
                top: 4px;
                border-radius: 50% 100% 50% 80%;
            }
        }
    }

    /*******************
    5.counter
*******************/

    .counter-box {
        position: relative;
        background-color: rgba($white, 0.1);
        border: 2px solid rgba($white, 0.1);
        overflow: hidden;
        border-radius: 7px;
        .counter-icon {
            i {
                position: absolute;
                right: 14px;
                bottom: -20px;
            }
        }
    }

    /*******************
    6.pricing
*******************/

    .pricing-plan {
        border-radius: 7px;
        max-width: 523px;
        margin: 0px auto;

        &.active {
            border: 2px solid $primary;
        }
    }

    /*******************
    7.testimonial
*******************/

    .testi-desc {
        background-color: $white;
        box-shadow: 0 10px 35px 0 rgba(154, 161, 171, 0.1);
        border-radius: 7px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background: $white;
            bottom: -6px;
            left: 52px;
            transform: rotate(45deg);
        }
    }

    .client-images {
        img {
            margin: 10px auto;
            opacity: 0.7;
        }
    }

    /*******************
    8.Faq
*******************/

    .faq-nav-tabs {
        .nav-item {
            margin: 0px 12px;
            box-shadow: 0 10px 35px 0 rgba(154, 161, 171, 0.1);
            &:first-child {
                margin-left: 0px;
            }
            &:last-child {
                margin-right: 0px;
            }
            .nav-link {
                padding: 1.2rem;
                color: $dark;
                &.active {
                    h4 {
                        color: $white;
                    }
                    i {
                        color: $white !important;
                    }
                }
            }
        }
    }

    .faq-tab-content {
        .faq-question-q-box {
            height: 30px;
            width: 30px;
            color: $primary;
            text-align: center;
            border-radius: 50%;
            float: left;
            font-weight: 600;
            line-height: 30px;
            background-color: rgba($primary, 0.15);
        }
    }

    /*******************
    9.Footer
*******************/

    .footer {
        left: 0;
        right: 0;
        bottom: 0;
        padding: auto;
        position: relative;
        .footer-logo {
            .logo-dark {
                display: $logo-auth-light-display;
            }
            .logo-light {
                display: $logo-auth-dark-display;
            }
        }
        .footer-cta {
            border-bottom: 1px solid rgba($light, 0.1);
        }
        .footer-title {
            font-size: 16px;
        }
        .footer-list {
            li {
                padding: 7px 0px;
                a {
                    color: rgba($gray-100, 0.6);
                    transition: all 0.4s;
                    &:hover {
                        color: $gray-100;
                    }
                }
            }
        }
    }

    /*******************
    10.Page title box
*******************/

    .page-header-box {
        padding: 175px 0px 60px 0px;
        color: $white;
        .breadcrumb {
            background: transparent;
            justify-content: center;
            .breadcrumb-item {
                a {
                    color: rgba($white, 0.6);
                }
                &.active {
                    color: $white;
                }
            }
        }
        .breadcrumb-item + .breadcrumb-item {
            &:before {
                color: $white;
            }
        }
    }

    /*******************
    11.about
*******************/

    .about-box {
        .about-index-number {
            width: 46px;
            height: 46px;
            line-height: 46px;
            font-size: 20px;
            text-align: center;
            background-color: lighten($primary, 32%);
            color: $primary;
            border-radius: 50%;
        }
    }

    // team

    .team-box {
        position: relative;
        overflow: hidden;
        transition: all 0.4s ease;
        border-radius: 7px;
        .team-overlay {
            background: none repeat scroll 0 0 rgba($black, 0.65);
            position: absolute;
            transition: all 0.5s ease-in-out 0s;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
        }

        .team-content {
            position: absolute;
            bottom: -60px;
            left: 0px;
            right: 0px;
            padding-left: 30px;
            padding-right: 30px;
            transition: all 0.5s ease-in-out 0s;
            opacity: 0;
            .social-icon {
                a {
                    display: inline-block;
                    height: 36px;
                    width: 36px;
                    line-height: 34px;
                    color: $white;
                    border: 1px solid $white;
                    border-radius: 50%;
                    transition: all 0.5s;
                    &:hover {
                        background: $primary;
                        border-color: $primary;
                    }
                }
            }
        }
        &:hover {
            .team-overlay {
                opacity: 1;
                visibility: visible;
            }
            .team-content {
                bottom: 50%;
                transform: translateY(50%);
                opacity: 1;
            }
        }
    }

    /*******************
    12.blog
*******************/

    .blog-content {
        position: relative;
        bottom: 14px;
        background: $card-bg;
        border-radius: 4px;
    }

    // blog sidebar

    .blog-right-sidebar {
        .widget-search {
            form {
                position: relative;
                .form-control {
                    font-size: 14px;
                }
                .search-button {
                    position: absolute;
                    top: 5px;
                    right: 4px;
                    background-color: transparent;
                    border: none;
                    color: $dark;
                    font-size: 18px;
                    transition: all 0.3s ease-out;
                    &:focus {
                        outline: 0;
                    }
                }
            }
        }

        .widget {
            ul {
                li {
                    padding: 12px 0px;
                    &:last-child {
                        padding-bottom: 0px;
                    }
                    a {
                        color: $dark;
                        display: block;
                    }
                }
            }

            &.widget-popular-post {
                .popular-post {
                    border-bottom: 1px solid lighten(#e6e6e6, 4%);
                    padding: 15px 0px;
                    img {
                        width: 64px;
                    }
                    &:last-of-type {
                        border: none;
                    }
                }
            }
            &.widget-tag-cloud {
                .tagcloud {
                    > a {
                        display: inline-block;
                        padding: 5px 10px;
                        border: 1px solid rgba($dark, 0.1);
                        border-radius: 2px;
                        color: lighten($dark, 10%);
                        font-size: 12.4px;
                        margin-top: 9px;
                        margin-right: 6px;

                        &:hover {
                            background: $primary;
                            border-color: $primary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    /*******************
    13.blog Details
*******************/

    .blog-details-box {
        .post-meta {
            .list-inline-item {
                margin-right: 24px;
                color: #7a7d84;
                a {
                    color: #7a7d84;
                }
            }
        }
        .blockquote {
            position: relative;

            .blockquote-icon {
                i {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    color: rgba($dark, 0.1);
                }
            }
        }
    }

    /*******************
    14.contact
*******************/

    .map {
        iframe {
            width: 100%;
            height: 300px;
            border: 0;
        }
    }

    .custom-form {
        form {
            .form-control {
                height: 43px;
                font-size: 15px;
                border-color: lighten(#7a7d84, 38%);
            }
            textarea {
                height: auto !important;
            }
        }
    }

    .error {
        margin: 8px 0px;
        display: none;
        color: $danger;
    }

    #ajaxsuccess {
        font-size: 16px;
        width: 100%;
        display: none;
        clear: both;
        margin: 8px 0px;
    }

    .error_message {
        padding: 10px;
        margin-bottom: 20px;
        text-align: center;
        color: $danger;
        background-color: lighten($danger, 36%);
        border-color: lighten($danger, 36%);
        border-radius: 5px;
        font-size: 14px;
    }

    .contact-loader {
        display: none;
    }

    #success_page {
        text-align: center;
        margin-bottom: 50px;
        h3 {
            color: $success;
            font-size: 22px;
        }
    }

    /******************
    15.Responsive
*******************/
    @media (min-width: 200px) and (max-width: 1199px) {
        .container-fluid {
            max-width: 95%;
        }
    }

    @media (min-width: 1200px) {
        .container-fluid {
            max-width: 85%;
        }
    }

    @media (min-width: 1600px) {
        .container-fluid {
            max-width: 70%;
        }
    }

    @media (min-width: 200px) and (max-width: 991px) {
        .navbar-custom {
            top: 0px;
            padding: 10px 0px !important;
            background-color: $white !important;
            box-shadow: 0 10px 35px 0 rgba(154, 161, 171, 0.1);

            .logo {
                .logo-dark {
                    display: inline-block;
                }
                .logo-light {
                    display: none;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        transition: all 0.4s;
                        color: rgba($dark, 0.8) !important;
                        margin: 0px;
                        padding: 6px 0px;
                        i {
                            &.mdi-chevron-down {
                                position: absolute;
                                right: 14px;
                            }
                        }
                    }
                }
                .nav-item.active .nav-link {
                    color: $primary !important;
                }
            }
        }
        .navbar-toggler {
            font-size: 24px;
            margin-top: 0px;
            margin-bottom: 0px;
            color: $dark;
        }

        .mo-mb-20 {
            margin-bottom: 20px !important;
        }

        .page-header-box {
            padding: 100px 0px 40px 0px;
        }
    }

    @media (max-width: 540px) {
        .bg-home {
            padding-top: 130px;
        }
        .home-title {
            font-size: 26px;
        }
        .media-list {
            padding: 0px !important;
            .media {
                .media-body {
                    margin-left: 1rem !important;
                }
            }
        }
        .post-meta {
            .list-inline-item {
                padding: 10px 0px;
            }
        }
    }
}
