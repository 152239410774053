// 
// sweetalert.scss
//

.swal2-modal {
    font-family: $font-family-base;
    box-shadow: 0 10px 33px rgba(0, 0, 0, .1);

    .swal2-title {
        font-size: 24px;
        font-weight: $font-weight-medium;
        padding:0;
    }

    .swal2-content {
        font-size: 16px;
    }

    .swal2-spacer {
        margin: 10px 0;
    }

    .swal2-file,
    .swal2-input,
    .swal2-textarea {
        border: 2px solid $gray-300;
        font-size: 16px;
        box-shadow: none;
    }

    .swal2-confirm.btn-confirm {
        background-color: $primary !important;
        font-size: $font-size-base;
    }

    .swal2-cancel.btn-cancel {
        background-color: $danger !important;
        font-size: $font-size-base;
    }

    .swal2-styled:focus {
        box-shadow: none !important;
    }

    .swal2-file:focus,
    .swal2-input:focus,
    .swal2-textarea:focus {
        outline: 0;
        border: 2px solid $primary;
    }
}

.swal2-icon {
    &.swal2-question {
        color: $primary !important;
        border-color: $primary !important;
    }

    &.swal2-success {
        border-color: $success !important;

        .line,
        [class^=swal2-success-line][class$=long],
        [class^=swal2-success-line] {
            background-color: $success !important;
        }

        .placeholder,
        .swal2-success-ring {
            border-color: $success !important;
        }
    }

    &.swal2-warning {
        color: $warning !important;
        border-color: $warning !important;
    }

    &.swal2-error {
        border-color: $danger;

        .line {
            background-color: $danger;
        }
    }

    &.swal2-info {
        border-color: $info;
        color: $info;
    }
}

.swal2-actions {
    button {
        font-size: 15px !important;
    }
}

.swal2-container {
    &.swal2-shown {
        background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    }
}

.swal2-progress-steps {
    .swal2-progress-step {
        background: $primary;

        &.swal2-active-progress-step {
            background: $primary;

            &~.swal2-progress-step,
            &~.swal2-progress-step-line {
                background: rgba($primary, 0.3);
            }
        }
    }

    .swal2-progress-step-line {
        background: $primary;
    }
}

.swal2-loader {
    border-color: $primary transparent $primary transparent;
}

/* Estilos para centrar la popup (modal), título y botones */
.swal2-popup {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Alinear elementos verticalmente */
    text-align: center;
}

.swal2-title{
    text-align: center !important;
    padding:0 !important
}
.swal2-html-container{
    margin:0.1 !important
}

.swal2-actions {
    display: flex;
    justify-content: center;
    /* Alinear botones horizontalmente */
}

.swal2-confirm,
.swal2-deny {
    width: 80px;
    min-width: 80px;
    /* Ajustar el ancho automáticamente */
}

/* Opcional: Ajustar estilos de la descripción */