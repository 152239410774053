// 
// kanbanboard.scss
//

.tasklist {
    min-height: 40px;
    margin-bottom: 0;

    li {
        background-color: lighten($card-bg, 2%);
        border: 1px solid $gray-300;
        border-left-width: 3px;
        // padding: 20px;
        margin-bottom: 15px;
        // border-radius: 3px;
        box-shadow: $components-shadow;

        &:last-of-type {
            margin-bottom: 0;
        }

        .btn-sm {
            padding: 2px 8px;
            font-size: 12px;
        }

        &.task-medium {
            border-left-color: $warning;
        }

        &.task-low {
            border-left-color: $success;
        }

        &.task-high {
            border-left-color: $danger;
        }

        //----------------
        &.task-URGENTE {
            border-left-color: #f1556c;
        }

        &.task-PROCESO {
            border-left-color: #3bafda;
        }

        &.task-LISTO {
            border-left-color: #1abc9c;
        }

        &.task-PENDIENTE-RECIBIR {
            border-left-color: #e94687;
        }

        &.task-PRUEBAS {
            border-left-color: #6559cc;
        }

        &.task-PENDIENTE {
            border-left-color: #ebe71bfd;
        }

        &.task-PAUSADO {
            border-left-color: #fd7e14;
        }

        &.task-REVISIÓN {
            border-left-color: #1457e7;
        }

        &.task-PENDIENTE-ACTUALIZACIÓN {
            border-left-color: #37cde6;
        }

        &.task-CAMBIOS {
            border-left-color: #8c98a5;
        }

    }

    .checkbox {
        margin-left: 20px;
        margin-top: 5px;
    }
}

.task-placeholder {
    border: 1px dashed $gray-300 !important;
    background-color: $gray-100 !important;
    padding: 20px;
}