//
// x-editable.scss
//

/*
.editable-clear-x {
    background: url("../../../images/plugins/clear.png") center center no-repeat;
}
*/

/*
.editableform-loading {
    background: url('../../../images/plugins/loading.gif') center center no-repeat;
}
*/

.editable-checklist label {
    display: block;
}

.editable-input{
    .form-control, .form-select{
      display: inline-block;
    }
  }
  
  .editable-buttons{
    margin-left: 7px;
    .editable-cancel{
      margin-left: 7px;
    }
  }

.editable-checklist > div {
    margin-bottom: 7px;
}